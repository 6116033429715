<template>
  <div class="pay-success">
    <img class="ps" src="../../../assets/pay-success.svg" />
    <div class="pay-desc" style="font-weight: bold;">支付成功</div>
    <div class="pay-desc">
      等待系统给您发送邀请邮件
    </div>
    <div class="invatation-desc">
      <div>
        1. 如果您是年费会员，在您确定<span style="font-weight: bold;">
          上车后 </span
        >将拥有专属邀请码，可将邀请码分享给朋友或者社交平台，您可以获得赏金，朋友下单可立减金额哦！。
      </div>

      <div style="margin-top: 0.12rem;">
        2. 可去「停车场」左侧红色按钮「邀请有奖」查看邀请码和活动详情。
      </div>
    </div>
    <div class="nav-btns">
      <button @click="back">
        下次再看
      </button>
      <button @click="bounty" class="bounty">
        去看看
      </button>
    </div>
  </div>
</template>
<script>

export default {
  name: 'CarpoolPaySuccess',
  data () {
    return {
      orderId: ''
    }
  },
  components: {},
  methods: {
    back () {
      this.$router.push('/carpool/kuaijieche')
    },
    bounty () {
      this.$router.push('/invitation-bounty')
    }
  },
  created () {
    this.$setNavShow(false)
    if (this.$route.query.orderId) {
      this.orderId = this.$route.query.orderId
    }
  }
}
</script>

<style lang="less" scoped>
.pay-success {
  width: 7.5rem;
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  align-items: center;
  .ps {
    margin-top: 0.38rem;
    width: 1.98rem;
    height: 1.98rem;
    &.traffic {
      width: 1.38rem;
      height: 1.38rem;
    }
  }
  .traffic-ps-desc {
    margin-top: 0.24rem;
    width: 7.02rem;
    border-radius: 0.1rem;
    box-shadow: 0px 0px 0.08rem 0.04rem rgba(0, 0, 0, 0.08);
    background: #ffffff;
    .key-title-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      .key-title {
        font-size: 0.32rem;
        color: #53cda9;
        margin: 0.24rem;
      }
    }
    .gas-info {
      font-size: 0.28rem;
      margin-left: 0.24rem;
      margin-bottom: 0.12rem;
      &:last-child {
        margin-bottom: 0.3rem;
      }
      .gas-available {
        color: #53cda9;
        margin-top: 0.12rem;
        cursor: pointer;
        word-break: break-all;
      }
    }
  }
  .traffic-ps-remark {
    color: #9f9f9f;
    font-size: 0.28rem;
    margin-left: 0.24rem;
    margin-top: 0.24rem;
    margin-right: 0.24rem;
    .remark {
      margin-bottom: 0.06rem;
    }
  }
  .pay-desc {
    margin-top: 0.08rem;
    font-size: 0.36rem;
    color: #4c4c4c;
  }
  .invatation-desc {
    margin-top: 0.36rem;
    display: flex;
    font-size: 0.28rem;
    color: #666666;
    flex-direction: column;
    padding: 0.2rem;
    background: #f9f9f9;
    border-radius: 0.02rem;
  }
  .nav-btns {
    margin-top: 0.4rem;
    width: 65%;
    display: flex;
    justify-content: space-between;
    button {
      min-width: 2.2rem;
      border: none;
      border-radius: 0.4rem;
      font-size: 0.32rem;
      color: #53cda9;
      border: 0.02rem solid #53cda9;
      background: #ffffff;
      height: 0.8rem;
      ::after {
        border: none;
      }
      &:last-child {
        color: #ffffff;
        background: linear-gradient(90deg, #53ccaa 0%, #5bd88f 100%);
      }
    }
  }
}
</style>
